import React from 'react'
import { Typography, Paper, Avatar, Button } from '@material-ui/core'
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import firebase from '../firebase'
import { withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		marginTop: theme.spacing(3),
	},
	logOut: {
		color: '#ffffff',
		backgroundColor: theme.palette.error.main
	}
})

function Dashboard(props) {
	const { classes } = props


	// const [quote, setQuote] = useState('')

	// useEffect(() => {
	// 	firebase.getCurrentUserQuote().then(setQuote)
	// }, [])

	if (!firebase.getCurrentUsername()) {
		// not logged in
		alert('Please login first')
		props.history.replace('/login')
		return null
	}



	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<VerifiedUserOutlined />
				</Avatar>
				<Typography component="h1" variant="h5">
					{/* Hello { firebase.getCurrentUsername() } */}
                    Hello!
				</Typography>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					onClick={sendLatestJobAppsToDispatch}
					className={classes.submit}>
					Send Latest JobApps To Dispatch
          		</Button>
				<Box m={10} />
			</Paper>
			<Button
				fullWidth
				variant="contained"
				onClick={logout}
				// color="error"
				className={classes.logOut}
			>
				Logout
          		</Button>
		</main>
	)

	async function logout() {
		await firebase.logout()
		props.history.push('/')
	}

	async function sendLatestJobAppsToDispatch() {
		alert("Request Sent")

		var sendApps = firebase.sendLatestJobAppsToDispatch();

		sendApps().then((result) => {
			console.log(`The result of the call is `)
		 })

		// const sendApps = firebase.sendLatestJobAppsToDispatch;
		// sendApps().then(() =>{
		// 	console.log("Yeah got executed")
		// })
		// addMessage({ text: messageText })
		// 	.then((result) => {
		// 		// Read result of the Cloud Function.
		// 		var sanitizedMessage = result.data.text;
		// 	})
		// 	.catch((error) => {
		// 		// Getting the Error details.
		// 		var code = error.code;
		// 		var message = error.message;
		// 		var details = error.details;
		// 		// ...
		// 	});

	}
}

export default withRouter(withStyles(styles)(Dashboard))