import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/firebase-functions'

const config = {
    apiKey: "AIzaSyB5EgthqZjnckAwbUUN72apFS1FXUMsRv8",
    authDomain: "ualocal170app.firebaseapp.com",
    databaseURL: "https://ualocal170app.firebaseio.com",
    projectId: "ualocal170app",
    storageBucket: "ualocal170app.appspot.com",
    messagingSenderId: "1039018272099",
    appId: "1:1039018272099:web:595e96b9eabd10ec9c5f56",
    measurementId: "G-P3KX63GHQ9"
}

class Firebase {
    constructor() {
        app.initializeApp(config)
        this.auth = app.auth()
        this.db = app.firestore()
        this.functions = app.functions()
    }

    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password)
    }

    logout() {
        return this.auth.signOut()
    }

    // async register(name, email, password) {
    //     await this.auth.createUserWithEmailAndPassword(email, password)
    //     return this.auth.currentUser.updateProfile({
    //         displayName: name
    //     })
    // }

    forgotPassword(email) {
        return this.auth.sendPasswordResetEmail(email)
    }

    addQuote(quote) {
        if (!this.auth.currentUser) {
            return alert('Not authorized')
        }

        return this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).set({
            quote
        })
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }

    getCurrentUsername() {
        return this.auth.currentUser
    }

    sendLatestJobAppsToDispatch() {
        return this.functions.httpsCallable('sendLatestJobAppsToDispatch')
    }

    // async getCurrentUserQuote() {
    //     const quote = await this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).get()
    //     return quote.get('quote')
    // }
}

export default new Firebase()